<template>
  <CMultiSelect
    :multiple="true"
    :search="true"
    optionsEmptyPlaceholder="No options placeholder"
    :searchPlaceholder="$t('Select')"
    :selected="[]"
    :selection="true"
    :selectionTypeCounterText="$t('type(s) selected')"
    @update="select"
    :options="embargoList"
  >
  </CMultiSelect>
</template>
<script>
export default {
  data() {
    return {
      embargoFields: [
        { value: "special", text: "Special goods" },
        { value: "private_license", text: "Private license" },
        { value: "banned_export", text: "Banned export" },
        { value: "banned_sea", text: "Banned sea" },
        { value: "banned_air", text: "Banned air" },
      ],
    };
  },
  computed: {
    embargoList() {
      return this.embargoFields.map((x) => {
        return {
          value: x.value,
          text: this.$t(x.text),
        };
      });
    },
  },
  methods: {
    select(value) {
      this.$emit("update:value", value);
    },
  },
};
</script>
