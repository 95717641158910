<template>
  <CMultiSelect
    :multiple="true"
    :search="true"
    optionsEmptyPlaceholder="No options placeholder"
    :searchPlaceholder="$t('Select')"
    :selected="[]"
    :selection="true"
    :selectionTypeCounterText="$t('type(s) selected')"
    @update="select"
    :options="list"
    v-if="list.length"
  >
  </CMultiSelect>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      tags: "product.product_tags.list",
    }),
    list() {
      return this.tags.map((x) => {
        return {
          value: x.id,
          text: x.name,
        };
      });
    },
  },
  created() {
    this.$store.dispatch("product.product_tags.fetch.if-first-time");
  },
  methods: {
    select(value) {
      this.$emit("update:value", value);
      this.$emit("change", value);
    },
  },
};
</script>
