<template>
  <div>
    <div class="mb-3">
      <TButton
        content="Create product"
        variant
        size
        color="info"
        :messageOptions="{ bold: true }"
        icon="cilPlus"
        to="products/new"
        class="px-4"
      />
    </div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="product.products"
      @click-clear-filter="clearFilter"
      resource="products"
      enterable
      reloadable
      deletable
      @click-reload="fetch"
    >
      <template #image_url="{ item }">
        <td>
          <SCardProductItems
            :item="{ image_url: item.image_url, name: item.name }"
            widthAuto
          />
        </td>
      </template>
      <template #price="{ item }">
        <td>
          <TMessageMoney
            :amount="item.price"
            :currency="item.currency_id || 'JPY'"
          />
        </td>
      </template>
      <template #suppliers="{ item }">
        <td>
          <TMessage
            v-if="!lodash.isEmpty(item.suppliers)"
            :content="item.suppliers[0].name"
            noTranslate
          />
          <TMessageNotFound v-else />
          <TMessage
            v-if="item.suppliers.length > 1"
            content="other suppliers"
            size="small"
            color="muted"
            :truncate="1"
          >
            <template #prefix> +{{ item.suppliers.length - 1 }} </template>
          </TMessage>
        </td>
      </template>
      <template #origin="{ item }">
        <td>
          <TMessage
            v-if="item.origin"
            :content="item.origin.name"
            noTranslate
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #category="{ item }">
        <td>
          <div
            v-if="item.categories && item.categories.length"
            class="d-flex flex-wrap"
          >
            <SMessageProductCategory
              v-for="category in item.categories"
              :key="category.id"
              class="mr-1 mb-1"
              :category="category"
            />
          </div>
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #tag="{ item }">
        <td>
          <div v-if="item.tags && item.tags.length" class="d-flex flex-wrap">
            <CBadge
              v-for="tag in item.tags"
              :key="tag.id"
              color="info"
              class="mr-1 mb-1"
              style="font-size: 85%"
            >
              {{ tag.name }}
            </CBadge>
          </div>
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #embargo="{ item }">
        <td>
          <TMessage v-if="item.banned_sea" content="Banned sea" color="danger">
            <template #prefix>
              <CIcon name="cilBan" class="mr-1" />
            </template>
          </TMessage>
          <TMessage v-if="item.banned_air" content="Banned air" color="danger">
            <template #prefix>
              <CIcon name="cilBan" class="mr-1" />
            </template>
          </TMessage>
          <TMessage
            v-if="item.banned_export"
            content="Banned export"
            color="danger"
          >
            <template #prefix>
              <CIcon name="cilBan" class="mr-1" />
            </template>
          </TMessage>
          <TMessage v-if="item.special" content="Special goods" color="danger">
            <template #prefix>
              <CIcon name="cilDiamond" class="mr-1" />
            </template>
          </TMessage>
          <TMessage
            v-if="item.private_license"
            content="Private license"
            color="danger"
          >
            <template #prefix>
              <CIcon name="cilLibraryBooks" class="mr-1" />
            </template>
          </TMessage>
        </td>
      </template>
      <template #updated_at="{ item }">
        <td>
          <TMessageDateTime :content="item.updated_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          :value.sync="filter.id"
          @update:value="filterChange"
          placeholder="Product Id"
        />
      </template>
      <template #image_url-filter>
        <TInputText
          :value.sync="filter.name"
          @update:value="filterChange"
          placeholder="Product name"
        />
      </template>
      <template #origin-filter>
        <SSelectProductOrigin
          :value.sync="filter.origin_id"
          @change="filterChange"
          :prepend="prepend"
        />
      </template>
      <template #category-filter>
        <SSelectProductCategory
          :value.sync="filter['categories']"
          @change="filterChange"
        />
      </template>
      <template #tag-filter>
        <SelectTags
          :key="tagsKey"
          :value.sync="filter['tags']"
          @change="filterChange"
        />
      </template>
      <template #suppliers-filter>
        <SSelectSupplier
          :value.sync="filter['suppliers.id']"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #embargo-filter>
        <SelectEmbargoFilter @update:value="setEmbargo" :key="embargoKey" />
      </template>
      <template #updated_at-filter>
        <TInputDateTimeRange
          :value.sync="dateRange"
          @update:value="setUpdatedAtFilter"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectEmbargoFilter from "./components/SelectEmbargoFilter.vue";
import SelectTags from "./components/SelectTags.vue";

export default {
  components: { SelectEmbargoFilter, SelectTags },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Product Id", _style: "min-width: 150px" },
        { key: "image_url", label: "Name", _style: "min-width: 350px" },
        {
          key: "suppliers",
          label: "Supplier",
          _classes: "text-nowrap",
          _style: "width: 300px; min-width: 300px",
        },
        { key: "price", label: "Price", _style: "min-width: 200px" },
        {
          key: "category",
          label: "Category",
          _classes: "text-nowrap",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "tag",
          label: "Tag",
          _classes: "text-nowrap",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "origin",
          label: "Origin",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "embargo",
          label: "Embargo",
          _style: "width: 180px; min-width: 180px",
          _classes: "text-nowrap",
        },
        {
          key: "updated_at",
          sorter: true,
          label: "Updated at",
          _style: "width: 220px",
        },
      ],
      embargo: [
        { key: "special", label: "Special goods" },
        { key: "private_license", label: "Private license" },
        { key: "banned_export", label: "Banned export" },
        { key: "banned_sea", label: "Banned sea" },
        { key: "banned_air", label: "Banned air" },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
      embargoFilters: {},
      embargoKey: "",
      tagsKey: "",
      prepend: [{ id: "", name: this.$t("All") }],
    };
  },
  mounted() {
    this.$store.dispatch("product.products.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "product.products.list",
    }),
  },
  methods: {
    fetch() {
      this.$store.dispatch("product.products.fetch");
    },
    filterChange() {
      const filter = this.lodash.mapKeys(
        { ...this.filter, ...this.embargoFilters },
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch("product.products.apply-query", filterFields);
    },
    setUpdatedAtFilter({ start, end }) {
      this.filter_between["updated_at"] = `${start},${end}`;
      this.filterChange();
    },
    setEmbargo(list) {
      this.embargoFilters = {};
      list.forEach((i) => {
        this.embargoFilters[i] = true;
      });
      this.filterChange();
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter_between = {};
      this.filter = {};
      this.embargoFilters = {};
      this.tagsKey = this.lodash.resetKey("select-tags");
      this.embargoKey = this.lodash.resetKey("select-embargo");
      this.filterChange();
    },
  },
};
</script>
